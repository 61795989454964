import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../../../components/layout"
import Band, { BandColors } from "../../../components/band"
import SEO from "../../../components/seo"
import PhoneValidationTopNav from "../../../components/top-nav/phone-validation-top-nav"

// CSS
import styles from "./phone-validation.module.css"
import shared from "../../../shared/shared.module.css"
import grid from "../../../shared/bootstrap-grid.module.css"

// SVG
import PhoneValidationPromoImg from "../../../svg/PhoneValidation/PV-Promo.svg"
import PhoneValidationOverviewImg from "../../../svg/PhoneValidation/PV-Overview.svg"
import PhoneValidationOverviewEnterpriseValidate from "../../../svg/PhoneValidation/PV-Overview-EnterpriseValidate.svg"
import PhoneValidationDNCDisplay from "../../../svg/PhoneValidation/PV-Table-DNC.svg"
import PhoneValidationBatch from "../../../svg/PhoneValidation/PV-Batch-Basic.svg"

// Icons
import ClipboardIcon from "../../../icons/clipboard.svg"
import GearsIcon from "../../../icons/gears.svg"
import PdfIcon from "../../../icons/pdf.svg"

// Files
import ProductBriefPdfSrc from "../../../files/Phone Validation Product Brief.pdf"

const PhoneValidationSummaryPage = () => (
  <Layout>
    <SEO title="Phone Validation" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `phone validation`]} />

    <PhoneValidationTopNav />

    <Band>
      <section className={styles.productPromoTop}>
        <h1 className={styles.productPromoTitle}>Phone Validation</h1>
        <p className={styles.productPromoSubTitle}>
          Validate Phone Numbers on Standard and Custom Objects!
        </p>

        <a href="https://www.youtube.com/watch?v=e9SK-5CtBhU" target="_blank" rel="noopener noreferrer" className={`${shared.btn} ${shared.btnPrimary} ${grid.mt3}`}>
          Watch Demo
        </a>

        <PhoneValidationPromoImg className={`${styles.productPromoImage} ${shared.w100} ${shared.hAuto}`} />

        <p className={styles.productPromoImageDesc}>
          Phone Validation is an AppExchange product for Salesforce Lightning and Classic which
          allows users to validate phone numbers on Account, Contact, Lead, and other Standard or
          Custom Object records.
        </p>
      </section>
    </Band>

    <Band color={BandColors.gray}>
      <div className={`${grid.row} ${grid.justifyContentBetween} ${grid.alignItemsCenter}`}>
        <div className={`${grid.colLg6}`}>
          <h2 className={`${styles.h2Big}`}>How it works</h2>
          <p className={`${styles.bigText}`}>
            The Tigerface Systems PhoneValidation App calls a 3rd party API provided by
            RealPhoneValidation.com to validate phone numbers directly in Salesforce.
          </p>
          <p className={`${styles.small}`}>
            Note: a RealPhoneValidation.com account is required.
          </p>
        </div>
        <div className={`${grid.colLg6} ${shared.hiddenMobile}`}>
          <PhoneValidationOverviewImg className={`${styles.overviewImg}`} />
        </div>
      </div>
    </Band>

    <Band>
      <h2 className={`${styles.h2Big} ${grid.mbLg5} ${grid.mbMd4} ${grid.pbLg5}`}>Feature Summary</h2>

      <div className={`${grid.row} ${grid.justifyContentBetween} ${grid.alignItemsCenter}`}>
        <div className={`${grid.colLg6}`}>
          <h3 className={`${styles.h3Big}`}>Phone Validation Data</h3>
          <p className={`${styles.mediumText}`}>
            Validate phone numbers and display validation data directly on Salesforce records.
          </p>
        </div>
        <div className={`${grid.colLg5} ${shared.hiddenMobile}`}>
          <PhoneValidationOverviewEnterpriseValidate className={`${shared.w100} ${shared.hAuto}`} />
        </div>
      </div>

      <div className={`${grid.mbLg5} ${grid.mbMd4} ${grid.pbLg5}`}></div>

      <div className={`${grid.row} ${grid.justifyContentBetween} ${grid.alignItemsCenter}`}>
        <div className={`${grid.colLg6} ${shared.hiddenMobile}`}>
          <PhoneValidationDNCDisplay className={`${styles.displayImg} ${shared.w100} ${shared.hAuto}`} />
        </div>
        <div className={`${grid.colLg5}`}>
          <h3 className={`${styles.h3Big}`}>Custom Components</h3>
          <p className={`${styles.mediumText}`}>
            Custom Related List and List View component to display and validate phone numbers.
          </p>
        </div>
      </div>

      <div className={`${grid.mbLg5} ${grid.mbMd4} ${grid.pbLg5}`}></div>


      <div className={`${grid.row} ${grid.justifyContentBetween} ${grid.alignItemsCenter}`}>
        <div className={`${grid.colLg6}`}>
          <h3 className={`${styles.h3Big}`}>Batch Processing </h3>
          <p className={`${styles.mediumText}`}>
            Validate thousands of phone numbers on multiple objects with batch processing.
          </p>
        </div>
        <div className={`${grid.colLg5} ${shared.hiddenMobile}`}>
          <PhoneValidationBatch className={`${shared.w100} ${shared.hAuto}`} />
        </div>
      </div>
    </Band>

    <Band color={BandColors.orange}>
      <h2 className={`${shared.textCenter} ${grid.mbLg5}`}>Learn More About Phone Validation</h2>
      <div className={`${grid.row} ${grid.justifyContentCenter} ${grid.mxLgN5}`}>
        <div className={`${grid.col8} ${grid.colLg4} ${grid.pxLg4}`}>
          <Link to="/products/phone-validation/features" className={`${shared.btn} ${shared.btnLight} ${styles.bigBtn}`}>
            <div className={styles.bigBtnIcon}>
              <ClipboardIcon />
            </div>
            <div className={styles.bigBtnText}>
              View The Full Feature Set
            </div>
          </Link>
        </div>
        <div className={`${grid.col8} ${grid.colLg4} ${grid.pxLg4} ${grid.mtLg0} ${grid.mt4}`}>
          <Link to="/products/phone-validation/user-guide" className={`${shared.btn} ${shared.btnLight} ${styles.bigBtn}`}>
            <div className={styles.bigBtnIcon}>
              <GearsIcon />
            </div>
            <div className={styles.bigBtnText}>
              View The User Guide
            </div>
          </Link>
        </div>
        <div className={`${grid.col8} ${grid.colLg4} ${grid.pxLg4} ${grid.mtLg0} ${grid.mt4}`}>
          <a href={ProductBriefPdfSrc} className={`${shared.btn} ${shared.btnLight} ${styles.bigBtn}`} target="_blank" rel="noopener noreferrer">
            <div className={styles.bigBtnIcon}>
              <PdfIcon />
            </div>
            <div className={styles.bigBtnText}>
              View The Product Brief
            </div>
          </a>
        </div>
      </div>
    </Band>
  </Layout>
);

export default PhoneValidationSummaryPage;